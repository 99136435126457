import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import MainDeimosLogo from './logoPrimary'
import Menu from './menu'
import { PropTypes } from 'prop-types'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as HeaderStyles from 'styles/header.module.css'
import { composeClasses } from 'utils'

function scrollFunction () {
  const element = document.getElementById('header')

  if (document.documentElement.scrollTop >= 50) {
    element && element.classList.add(HeaderStyles.shrink)
  } else {
    element && element.classList.remove(HeaderStyles.shrink)
  }
}

const Header = ({ menuItems }) => {
  const [arrow, setArrow] = useState(faAngleDown)
  const [isVisible, setIsVisible] = useState(true)
  const [hideMenu, setHideMenu] = useState(false)

  const showMegamenu = () => {
    setIsVisible(!isVisible)
    setHideMenu(!hideMenu)
    setTimeout(() => {
      setIsVisible(isVisible)
    }, 1000)
  }

  useEffect(() => {
    window.onscroll = scrollFunction
  }, [])

  const topLevelMenuItem =
    menuItems && menuItems.edges[0].node.menuFieldGroup.topLevelMenuItem

  return (
    <header
      className={HeaderStyles.header}
      id='header'
      style={{ zIndex: 4 }}>
      <div className={HeaderStyles.headerContainer}>
        <div className={HeaderStyles.primaryLogo}>
          {typeof window !== 'undefined' &&
            window.location.pathname === '/' && <MainDeimosLogo />}
          {typeof window !== 'undefined' && window.location.pathname !== '/' && (
            <Link to='/'>
              <MainDeimosLogo />
            </Link>
          )}
          <h6>The Cloud Native Developer &amp; Security Operations Company</h6>
        </div>
        <div className={HeaderStyles.menu}>
          <Menu menuItems={menuItems} />
        </div>
        <ul className={HeaderStyles.desktopLinks}>
          {topLevelMenuItem.map((item, index) => {
            const slug = item.slug === 'index' ? '' : item.slug
            const hasSubmenu = item.hasSubmenu
            const menuVisible = () => {
              hasSubmenu && setIsVisible(!isVisible)
              hasSubmenu && setHideMenu(!hideMenu)
              setTimeout(() => {
                setIsVisible(isVisible)
              }, 1000)
            }
            const secondLevelMenuItem = hasSubmenu && item.secondLevelMenuItem
            return (
              <li
                data-link-services={hasSubmenu || undefined}
                key={index.toString()}
              >
                <Link
                  activeClassName={HeaderStyles.activeLink}
                  onClick={menuVisible}
                  onMouseEnter={hasSubmenu ? () => setArrow(faAngleUp) : null}
                  onMouseLeave={hasSubmenu ? () => setArrow(faAngleDown) : null}
                  to={`/${slug}`}
                >
                  {item.label} {hasSubmenu && <FontAwesomeIcon icon={arrow} />}
                </Link>
                {hasSubmenu && isVisible && (
                  <ul className={composeClasses(HeaderStyles.subNav, index > 1 ? HeaderStyles.singleItem : '')}>
                    <div className={HeaderStyles.subNavDiv}>
                      {secondLevelMenuItem.map((subItem, index) => (
                        <li
                          className={composeClasses(
                            HeaderStyles.subNavDivli,
                            HeaderStyles.subNavLink
                          )}
                          key={index.toString()}
                        >
                          <Link
                            activeClassName={HeaderStyles.activeNavLink}
                            className={HeaderStyles.subLink}
                            onClick={showMegamenu}
                            to={`/${subItem.submenuItemUrl}`}
                          >
                            <p className={HeaderStyles.subNavFontWeight}>
                              {subItem.submenuItemName}
                            </p>
                          </Link>
                          {subItem.innerSubmenu &&
                            subItem.thirdLevelMenuItem.map(
                              (innerMenuItem, index) => {
                                return (
                                  <div
                                    className={
                                      index === 0 ? HeaderStyles.hide : ''
                                    }
                                    key={index.toString()}
                                  >
                                    <Link
                                      activeClassName={composeClasses(
                                        HeaderStyles.activeNavLink,
                                        HeaderStyles.activeSubNavLink,
                                        HeaderStyles.innerSubNavLinkActive
                                      )}
                                      className={HeaderStyles.innerSubNavLink}
                                      onClick={showMegamenu}
                                      to={`/${innerMenuItem.innerSubmenuUrl}`}
                                    >
                                      {innerMenuItem.innerSubmenuItem}
                                    </Link>
                                  </div>
                                )
                              }
                            )}
                        </li>
                      ))}
                    </div>
                  </ul>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  menuItems: PropTypes.object.isRequired
}

export default Header
