import React from 'react'

import * as styles from 'styles/animations.module.css'

const Loading = () => (
  <div className={styles.ldsEllipsis}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Loading
