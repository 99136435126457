/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { jsonId } from 'utils'

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
        structuredData {
          address
          description
          email
          foundingDate
          foundingLocation
          locality
          numberOfEmployees
          telephone
          founders {
            email
            familyName
            gender
            givenName
            image
            jobTitle
            nationality
          }
        }
      }
    }
  }
`

const SEO = ({ description, article, title, image }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultTitle,
      defaultDescription,
      defaultImage,
      siteUrl,
      structuredData,
      twitterUsername,
      titleTemplate
    }
  } = site
  const seo = {
    article: article ? 'article' : 'website',
    description: description || defaultDescription,
    image: image || defaultImage,
    title: title || defaultTitle,
    url: `${siteUrl}${pathname}`
  }

  const titleHolder = `${seo.title} | Deimos`
  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}>

      <meta
        content={seo.description}
        name='description'
      />
      <meta
        content={seo.image}
        name='image'
      />
      <meta
        content={titleHolder}
        itemProp='name' />

      <meta
        content={seo.description}
        itemProp='description'
      />

      <meta
        content={seo.image}
        itemProp='image'
      />

      <meta
        content='Deimos'
        name='og:site_name'
      />
      {seo.url &&
        <meta
          content={seo.url}
          property='og:url'
        />}
      {seo.article &&
        <meta
          content={seo.article}
          property='og:type'
        />}
      {article &&
        <>
          <meta
            content='2013-09-17T05:59:00+01:00'
            property='article:published_time'
          />

          <meta
            content='2013-09-16T19:08:47+01:00'
            property='article:modified_time'
          />

          <meta
            content='Blog'
            property='article:section'
          />

          <meta
            content='Article'
            property='article:tag'
          />
        </>}
      {seo.title &&
        <meta
          content={titleHolder}
          property='og:title'
        />}
      {seo.description && (
        <meta
          content={seo.description}
          property='og:description'
        />
      )}
      {seo.image &&
        <meta
          content={seo.image}
          property='og:image'
        />}
      {<meta
        content='209243376401776'
        property='fb:admins'
      />}

      {/*  Twitter specific SEO */}
      <meta
        content='summary_large_image'
        name='twitter:card'
      />
      <meta
        content={twitterUsername}
        name='twitter:creator'
      />
      <meta
        content={twitterUsername}
        name='twitter:site'
      />
      {seo.title &&
        <meta
          content={titleHolder}
          name='twitter:title'
        />}
      {seo.description && (
        <meta
          content={seo.description}
          name='twitter:description'
        />
      )}
      {seo.image &&
        <meta
          content={seo.image}
          name='twitter:image'
        />}
      {seo.image &&
        <meta
          content={seo.image}
          name='twitter:image:src'
        />}
      {jsonId({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        address: {
          '@type': 'PostalAddress',
          addressLocality: structuredData.locality,
          postalCode: structuredData.postalCode,
          streetAddress: structuredData.address
        },
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'Customer service',
          telephone: structuredData.telephone
        },
        description: structuredData.description,
        founder: structuredData.founders.map(founder => ({
          '@type': 'Person',
          email: founder.email,
          familyName: founder.familyName,
          gender: 'Male',
          givenName: founder.givenName,
          image: founder.image,
          jobTitle: founder.jobTitle,
          nationality: founder.nationality
        })),
        foundingDate: {
          '@type': 'Date',
          dateCreated: structuredData.foundingDate
        },
        foundingLocation: structuredData.foundingLocation,
        logo: structuredData.logo,
        name: 'Deimos Cloud',
        numberOfEmployees: {
          '@type': 'QuantitativeValue',
          unitText: 'martians',
          value: structuredData.numberOfEmployees
        },
        sameAs: [
          'https://twitter.com/deimoscloud',
          'https://linkedin.com/company/deimos-cloud',
          'https://www.instagram.com/deimoscloud/'
        ],
        url: siteUrl
      })}
    </Helmet>
  )
}

SEO.defaultProps = {
  article: false,
  description: null,
  image: null,
  title: ''
}

SEO.propTypes = {
  article: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default SEO
