import React, { useContext } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import NewsletterSignUp from 'components/newsletterSignup'
import layoutContext from 'contexts/layoutContext'
import { composeClasses } from 'utils'
import FooterSocialLinks from 'components/footerSocialLinksSection'

import * as FooterStyles from '../styles/footer.module.css'

import deimosIcon from '../images/deimosIcon.svg'
import footerLoc from 'images/footer/footerLoc.svg'
import footerMail from 'images/footer/footerMail.svg'
import footerTel from 'images/footer/footerTel.svg'

const Footer = ({ footers }) => {
  const { hideFooter } = useContext(layoutContext)
  const footerData = footers.edges[0].node.footersFieldGroup
  const contactMethods = footerData.contactDetails

  return (
    <footer
      className={composeClasses(
        FooterStyles.footer,
        hideFooter && FooterStyles.hideFooter
      )}
    >
      <div className={FooterStyles.footerContainer}>
        <div className={FooterStyles.locationContainer}>
          <div className={FooterStyles.logoPart}>
            <img
              alt='deimosFooterIcon'
              className={FooterStyles.deimosFooter}
              height='49'
              src={deimosIcon}
              width='79'
            />
          </div>
          <div className={FooterStyles.headingContainer}>
            <h3>Contact us</h3>
          </div>
          <div className={FooterStyles.infoPart}>
            <img
              alt='footerIcons'
              className={composeClasses(
                FooterStyles.footerIcons,
                FooterStyles.locationIcon
              )}
              height='24'
              src={footerLoc}
              width='24'
            />
            <p className={FooterStyles.footerData}>
              {footerData.street}, {footerData.suburb}, {footerData.city},{' '}
              {footerData.postalCode}, <br />
              {footerData.country}
            </p>
          </div>
          <div className={FooterStyles.infoPart}>
            <img
              alt='footerIcons'
              className={FooterStyles.footerIcons}
              height='17'
              src={footerMail}
              width='24'
            />
            {contactMethods.map((contact, index) => {
              if (contact.contactMethodKey === 'email') {
                return (
                  <span key={index.toString()}>
                    <p>
                      <a
                        className={FooterStyles.blockItemNumber}
                        href={`mailto:${contact.contactMethodValue}`}
                      >
                        {contact.contactMethodValue}
                      </a>
                    </p>
                  </span>
                )
              }
            })}
          </div>
          <div className={FooterStyles.infoPart}>
            <img
              alt='footerIcons'
              className={FooterStyles.footerIcons}
              height='24'
              src={footerTel}
              width='24'
            />
            {contactMethods.map((contact, index) => {
              if (contact.contactMethodKey === 'telephone') {
                return (
                  <span key={index.toString()}>
                    <p>
                      <a
                        className={FooterStyles.blockItemNumber}
                        href={`tel:${contact.contactMethodValue}`}
                      >
                        {contact.contactMethodValue}
                      </a>
                    </p>
                  </span>
                )
              }
            })}
          </div>
        </div>
        <div className={FooterStyles.mailContainer}>
          <div
            className={composeClasses(
              FooterStyles.engageContainer,
              FooterStyles.containerSub
            )}
          >
            <h3>Engage With us</h3>
            <div>
              <FooterSocialLinks socialLinks={footerData.socialLinks} />
            </div>
          </div>
        </div>
        <div className={FooterStyles.mailContainer}>
          <div className={FooterStyles.containerSub}>
            <NewsletterSignUp />
          </div>
        </div>
      </div>
      <div className={FooterStyles.subFooterBar}>
        <div className={FooterStyles.subFooterBarInner}>
          <div>
            <p>Copyright &#169; Deimos Cloud</p>
          </div>
          <div className={FooterStyles.sitemapPrivacyPolicy}>
            <Link
              className={FooterStyles.privacyLink}
              to={'/sitemap'}>
              Sitemap
            </Link>
            <Link
              className={FooterStyles.privacyLink}
              to={'/privacy-policy'}>
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footers: PropTypes.object.isRequired
}

export default Footer
