import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as ContactStyles from 'styles/contact.module.css'
import { isDef, formatHTMLEntity, composeClasses, isTrue } from 'utils'

export const Input = ({ errMsg, className, label, name, error, ...props }) => {
  return (
    <div>
      <label
        className={ContactStyles.label}
        htmlFor={name}>
        {label && (
          <span
            className={composeClasses(
              ContactStyles.labelText,
              errMsg && ContactStyles.errorLabelText
            )}
          >
            {label}
          </span>
        )}
        <input
          className={composeClasses(
            className || ContactStyles.inputField,
            errMsg && ContactStyles.errorInput
          )}
          name={name}
          {...props}
        />
        {isTrue(!error)
          ? (
            isDef(errMsg) && <span className={ContactStyles.errorIcon}>!</span>
          ) : (
            isDef(errMsg) && <span className={ContactStyles.errorIconAlt}>!</span>
          )}
      </label>
      {isDef(errMsg) && <span className={ContactStyles.info}>{errMsg}</span>}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  errMsg: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
}

export const TextArea = ({ errMsg, className, label, name, ...props }) => {
  return (
    <div>
      <label
        className={ContactStyles.label}
        htmlFor={name}>
        {label && (
          <span
            className={composeClasses(
              ContactStyles.labelText,
              errMsg && ContactStyles.errorLabelText
            )}
          >
            {label}
          </span>
        )}
        <textarea
          className={composeClasses(
            ContactStyles.textareaField,
            errMsg && ContactStyles.errorInput
          )}
          name={name}
          {...props}
        />
        {isDef(errMsg) && <span className={ContactStyles.errorIcon}>!</span>}
      </label>
      {isDef(errMsg) && <span className={ContactStyles.info}>{errMsg}</span>}
    </div>
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  errMsg: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
}

export const Button = ({
  className,
  disabled,
  name,
  children,
  type,
  ...props
}) => {
  return (
    <label
      className={ContactStyles.labelButton}
      htmlFor={name}>
      <button
        className={className}
        disabled={disabled}
        name={name}
        type={type || 'button'}
        {...props}
      >
        {children}
      </button>
    </label>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string
}

export const CheckboxList = ({
  className,
  disabled,
  name,
  label,
  options,
  ...props
}) => {
  const [isSelected, setIsSelected] = useState(false)

  const showHide = (e) => {
    const { value } = e.target

    if (value === 'Other') {
      setIsSelected(!isSelected)
    }
  }

  return (
    <div className={ContactStyles.checkboxContainer}>
      <label
        className={ContactStyles.checkboxLabel}
        htmlFor={name}
        id='popupCheckboxLabelList'
      >
        <label
          className={ContactStyles.strongLabelText}
        >
          <span>{label}<span className={ContactStyles.optionalStyle}>(optional)</span></span>
        </label>
        {options && options[0].map((option, index) => {
          return (
            <span
              className={className}
              key={String(index)}>
              <input
                disabled={disabled}
                id={`${option.value}`}
                name={name}
                onClick={e => showHide(e)}
                type='checkbox'
                value={option.value}
                {...props}
              />
              {formatHTMLEntity('', '00A0')}
              {formatHTMLEntity('', '00A0')}
              <label htmlFor={`popup-${option.value}`}>
                {option.value}
              </label>
            </span>
          )
        })
        }
        {isSelected &&
          <div className={ContactStyles.formTextarea}>
            <TextArea
              id='servicesMessage'
              maxLength='250'
              name='servicesMessage'
            />
          </div>
        }
      </label>
    </div>
  )
}

CheckboxList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
}
