/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Header from 'components/header'
import Footer from 'components/footer'
import SEO from 'components/seo'
import { LayoutProvider } from 'contexts/layoutContext'

import 'styles/global.css'
import ContactFormComponent from 'components/contactFormComponent'

const query = graphql`
  query {
    wordPress {
      customMenus {
        edges {
          node {
            menuFieldGroup {
              topLevelMenuItem {
                hasSubmenu
                label
                slug
                secondLevelMenuItem {
                  submenuItemName
                  submenuItemUrl
                  innerSubmenu
                  thirdLevelMenuItem {
                    innerSubmenuItem
                    innerSubmenuUrl
                  }
                }
              }
            }
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              city
              contactDetails {
                contactMethodValue
                contactMethodKey
              }
              country
              postalCode
              socialLinks {
                socialLogo
                socialUrl
                socialTitle
              }
              street
              suburb
              contactFormServiceName {
                serviceLabel
              }
            }
          }
        }
      }
    }
  }
`

const Layout = ({ children }) => {
  const { wordPress: data } = useStaticQuery(query)

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 0) {
        if (!window.document.body.classList.contains('scrolled')) {
          window.document.body.classList.add('scrolled')
        }
      } else {
        if (window.document.body.classList.contains('scrolled')) {
          window.document.body.classList.remove('scrolled')
        }
      }
    })
  }, [])

  return (
    <LayoutProvider>
      {process.env.GATSBY_ACTIVE_ENV === 'staging' && (
        <Helmet>
          <meta
            content='max-snippet:20'
            name='robots' />
        </Helmet>
      )}
      <SEO />
      <Header menuItems={data.customMenus} />
      <div className='content'>
        <main>{children}</main>
        <ContactFormComponent services={data.footers} />
      </div>
      <Footer footers={data.footers} />
    </LayoutProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired
  ])
}

export default Layout
