/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import PropTypes from 'prop-types'

export const wrapRootElement = ({ element }) => (
  <>{element}</>
)

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById('preloader').style.display = 'none'
  }, 1000)
}

export const registerServiceWorker = () => true

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.element.isRequired
  ])
}
