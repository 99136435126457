import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { composeClasses } from 'utils'

import * as FooterStyles from '../styles/footer.module.css'

const FooterSocialLinks = ({
  socialLinks,
  containerStyles = [],
  iconStyles = []
}) => {
  return (
    <div
      className={composeClasses(
        FooterStyles.socialIconContainer,
        ...containerStyles
      )}
    >
      {socialLinks.map(({ socialLogo, socialUrl, socialTitle }, index) => {
        return (
          <div
            className={FooterStyles.socialLinksSection}
            key={index.toString()}>
            <a
              aria-label={socialTitle}
              className={composeClasses(FooterStyles.socialIcon, ...iconStyles)}
              href={socialUrl}
              rel='noopener noreferrer'
              target='_blank'
            >
              {parse(socialLogo)}
              <span>{index.toString()}</span>
            </a>
            <span>{socialTitle}</span>
          </div>
        )
      })}
    </div>
  )
}

FooterSocialLinks.propTypes = {
  containerStyles: PropTypes.array,
  iconStyles: PropTypes.array,
  socialLinks: PropTypes.array.isRequired
}

export default FooterSocialLinks
