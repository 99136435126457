import fallbackImage from 'images/fallbackimage.svg'
import dayjs from 'dayjs'
import React from 'react'

// check if a string or array is not empty
export const isDef = val => val && val.length > 0

// check if a vavlue is true
export const isTrue = val => val && val === true

/**
 * Compose a text containing a HTML Entity for
 * display in JSX
 * @param  {String} textVal
 * @param {String} entityHexVal
 * @param {Boolean} swapTextVal
 * @param {String} prefix
 * @returns {String} text including the HTML Entity
 */
export const formatHTMLEntity = function (
  textVal,
  entityHexVal,
  swapTextVal = false,
  prefix = ''
) {
  const entity = prefix + String.fromCharCode(parseInt(entityHexVal, 16))

  if (swapTextVal) {
    return entity + (textVal ? ' ' + textVal : '')
  }

  return (textVal ? textVal + ' ' : '') + entity
}

/**
 * Compose a number of styles together easily
 * @param {String} styles Classes/styles to be applied
 * @returns {String} Combined classes
 */
export const composeClasses = (...styles) => {
  let classes = ''

  styles.forEach(arg => {
    if (arg) classes += `${arg} `
  })

  return classes.trim()
}

/**
 * Checks if an array is empty
 * @param {Array} arr Array to be tested
 * @returns {Boolean} Boolean value
 */
export const isNotEmptyArray = arr => Array.isArray(arr) && arr.length > 0

const addLeadingZero = value => (value < 10 ? `0${value}` : value)

export const formatDisplayDate = (dateValue) => {
  const date = new Date(dateValue)
  if (!date) return ''
  return `${date.getDay()} ${date.toLocaleString('default', { month: 'long' })} ${date.getUTCFullYear()}`
}

export const formatDate = (dateString, delimiter = ' | ', dateFormat = null) => {
  if (typeof dateString !== 'string') return ''
  const dateInstance = parseDate(dateString, dateFormat)
  return `${addLeadingZero(
    dateInstance.getDate()
  )}${delimiter}${addLeadingZero(
    dateInstance.getMonth() + 1
  )}${delimiter}${addLeadingZero(dateInstance.getFullYear())}`
}

function parseDate (input, format) {
  format = format || 'yyyy-MM-ddThh-mm-ss' // default format
  const parts = input.match(/(\d+)/g)
  let i = 0
  const fmt = {}
  // extract date-part indexes from the format
  format.replace(/(yyyy|dd|mm|MM|hh|ss)/g, function (part) { fmt[part] = i++ })

  return new Date(parts[fmt['yyyy']], parts[fmt['MM']] - 1, parts[fmt['dd']], parts[fmt['hh']], parts[fmt['mm']], parts[fmt['ss']])
}

// Back navigation
export const goBack = () => {
  window.history.go(-1)
}

// Get form fields from form submission
export const serializeForm = target => {
  const inputs = target.querySelectorAll('input, select, textarea')
  const fields = {}

  inputs.forEach(input => {
    if (input.type === 'checkbox') {
      if (input.checked) {
        if (isNotEmptyArray(fields[input.name])) {
          fields[input.name].push(input.value.trim())
        } else {
          fields[input.name] = [input.value.trim()]
        }
      }
    } else {
      fields[input.name] = input.value.trim()
    }
  })

  return fields
}

export const getPostFeaturedImageUrl = post => {
  return post.featuredImage === null ? fallbackImage : post.featuredImage.node.sourceUrl
}

export const stripHTMLTags = strInputCode => {
  return strInputCode.replace(/<\/?[^>]+(>|$)/g, '')
}

export const stripPTags = string => {
  return string.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '')
}

export const formatDateWithDayJs = (date) => {
  return dayjs(date).format('DD/MM/YYYY')
}

export const jsonId = (children) => {
  return <script type='application/ld+json'>{JSON.stringify(children)}</script>
}
