import React from 'react'
import PropTypes from 'prop-types'

const defaultContextValue = {
  // set your initial data shape here
  hideFooter: false,
  setLayout: () => {}
}

const LayoutContext = React.createContext(defaultContextValue)

class LayoutProvider extends React.Component {
  constructor () {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      setLayout: this.setData
    }
  }

  setData (newData) {
    this.setState(prevState => ({
      ...prevState.data,
      ...newData
    }))
  }

  render () {
    return (
      <LayoutContext.Provider value={this.state}>
        {this.props.children}
      </LayoutContext.Provider>
    )
  }
}

LayoutProvider.propTypes = {
  children: PropTypes.node
}

export { LayoutContext as default, LayoutProvider }
