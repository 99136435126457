import React from 'react'

function SvgComponent (props) {
  return (
    /* eslint-disable */
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <g
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M21 79l57-57M78.5 78.5l-57-57"></path>
      </g>
    </svg>
  )
}

export default SvgComponent
