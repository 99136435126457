// extracted by mini-css-extract-plugin
export var footerContainer = "footer-module--footerContainer--FkmJL";
export var headingContainer = "footer-module--headingContainer--Jg9eu";
export var containerSub = "footer-module--containerSub--Ayh7X";
export var locationContainer = "footer-module--locationContainer--VD1pj";
export var mailContainer = "footer-module--mailContainer--VR2Vl";
export var deimosFooter = "footer-module--deimosFooter--0mwdA";
export var footerIcons = "footer-module--footerIcons--Jv93H";
export var footer = "footer-module--footer--M7sYz";
export var socialLinksSection = "footer-module--socialLinksSection---CZl4";
export var socialIconContainer = "footer-module--socialIconContainer--puJja";
export var hideFooter = "footer-module--hideFooter--aqZ0F";
export var socialIcon = "footer-module--socialIcon--OxG7w";
export var footerLogo = "footer-module--footerLogo--Zkb0+";
export var infoPart = "footer-module--infoPart--9bmhQ";
export var footerData = "footer-module--footerData--0UeNd";
export var block = "footer-module--block--zdL6g";
export var blockItemNumber = "footer-module--blockItemNumber--D4roU";
export var logoPart = "footer-module--logoPart--AHT3c";
export var subFooterBar = "footer-module--subFooterBar--5DUcL";
export var subFooterBarInner = "footer-module--subFooterBarInner--8NFEy";
export var sitemapPrivacyPolicy = "footer-module--sitemapPrivacyPolicy--1+pVo";
export var privacyLink = "footer-module--privacyLink--gKH9l";
export var engageContainer = "footer-module--engageContainer--jkHEc";
export var locationIcon = "footer-module--locationIcon--q9zFE";