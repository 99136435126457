/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  accordionItem,
  accordionTitle,
  accordionTitleThree,
  contentContainer,
  contentNumber,
  accordionContent,
  accordionTeamContent,
  contentTitleStyle,
  contentTitleStyleTwo,
  certificationNumber,
  accordionTitleTwo,
  accordionItemTwo,
  accordionItemThree,
  teamAccordionWrapper,
  accordionMemberContent,
  faqContentContainer,
  accordionTitleFour,
  hide
} from '../styles/accordion.module.css'
import arrowDown from '../images/team/arrow-down.svg'
import arrowUp from '../images/team/arrow-up.svg'

const Accordion = ({
  numbers,
  content,
  contentTitle,
  menuStyle,
  imageId,
  teamPage,
  memberPage,
  certification,
  isExpanded
}) => {
  const [isActive, setIsActive] = useState(false)
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      {isExpanded ? (
        <div className={teamPage ? teamAccordionWrapper : ''}>
          <div
            className={
              menuStyle
                ? accordionItemTwo
                : teamPage
                  ? accordionItemThree
                  : memberPage
                    ? accordionItemThree
                    : accordionItem
            }
          >
            <button
              className={
                menuStyle
                  ? accordionTitleTwo
                  : teamPage
                    ? accordionTitleThree
                    : memberPage
                      ? accordionTitleFour
                      : accordionTitle
              }
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className={teamPage ? contentContainer : memberPage ? contentContainer : contentContainer}>
                <div className={teamPage ? hide : memberPage ? hide : contentNumber}>
                  {numbers && parse(numbers)}
                </div>
                <div
                  className={
                    menuStyle
                      ? contentTitleStyleTwo
                      : teamPage
                        ? contentTitleStyleTwo
                        : memberPage
                          ? contentTitleStyleTwo
                          : contentTitleStyle
                  }
                >
                  {contentTitle && contentTitle}
                </div>
                <div
                  className={
                    imageId === 'Cloud-Native Support Engineering' ? hide : ''
                  }
                >
                  {isOpen ? (
                    <img
                      align="right"
                      alt="arrowUp"
                      height={12}
                      id={imageId}
                      src={arrowUp}
                      width={24}
                    />
                  ) : (
                    <img
                      align="right"
                      alt="arrowDown"
                      height={12}
                      id={imageId}
                      src={arrowDown}
                      width={24}
                    />
                  )}
                </div>
              </div>
            </button>
            {isOpen && (
              <div
                className={teamPage ? accordionTeamContent : memberPage ? faqContentContainer : accordionContent}
              >
                {content && content}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={teamPage ? teamAccordionWrapper : ''}>
          <div
            className={
              menuStyle
                ? accordionItemTwo
                : teamPage
                  ? accordionItemThree
                  : memberPage
                    ? accordionItemThree
                    : accordionItem
            }
          >
            <button
              className={
                menuStyle
                  ? accordionTitleTwo
                  : teamPage
                    ? accordionTitleThree
                    : memberPage
                      ? accordionTitleFour
                      : accordionTitle
              }
              onClick={() => setIsActive(!isActive)}
            >
              <div className={teamPage ? contentContainer : memberPage ? contentContainer : menuStyle ? ' ' : faqContentContainer}>
                <div className={teamPage ? hide : memberPage ? hide : contentNumber}>
                  {numbers && parse(numbers)}
                </div>
                <div
                  className={
                    menuStyle
                      ? contentTitleStyleTwo
                      : teamPage
                        ? contentTitleStyleTwo
                        : memberPage
                          ? contentTitleStyleTwo
                          : contentTitleStyle
                  }
                >
                  {contentTitle && contentTitle}
                  <span className={teamPage ? hide : certification ? certificationNumber : hide}>
                    ({numbers && parse(numbers)})
                  </span>
                </div>
                <div
                  className={
                    imageId === 'Cloud-Native Support Engineering' ? hide : ''
                  }
                >
                  {isActive ? (
                    <img
                      align="right"
                      alt="arrowUp"
                      height={12}
                      id={imageId}
                      src={arrowUp}
                      width={24}
                    />
                  ) : (
                    <img
                      align="right"
                      alt="arrowDown"
                      height={12}
                      id={imageId}
                      src={arrowDown}
                      width={24}
                    />
                  )}
                </div>
              </div>
            </button>
            {isActive && (
              <div
                className={teamPage ? accordionTeamContent : memberPage ? accordionMemberContent : accordionContent}
              >
                {content && content}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

Accordion.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ]),
  contentTitle: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  numbers: PropTypes.string.isRequired
}

export default Accordion
