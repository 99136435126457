// extracted by mini-css-extract-plugin
export var Stars = "animations-module--Stars--0lk7v";
export var Rocket = "animations-module--Rocket--J8bcy";
export var DotGrid = "animations-module--DotGrid--E+Nkl";
export var Graph = "animations-module--Graph--GR7XG";
export var Scalebar = "animations-module--Scalebar--n5CKG";
export var SmallPlanet = "animations-module--SmallPlanet--irg-0";
export var ldsEllipsis = "animations-module--ldsEllipsis--T14BB";
export var ldsEllipsis1 = "animations-module--ldsEllipsis1--eMTLu";
export var ldsEllipsis2 = "animations-module--ldsEllipsis2---KCl7";
export var ldsEllipsis3 = "animations-module--ldsEllipsis3--9uC2l";