import React from 'react'
import PropTypes from 'prop-types'
import { composeClasses } from '../utils'
import { Input, TextArea, CheckboxList, Button } from 'components/form'
import Loading from 'components/Loading'
import { formatHTMLEntity, serializeForm } from 'utils'

import * as ContactStyles from 'styles/contact.module.css'

import successIcon from 'images/contact/succesPageIconDesktop.svg'
import closeIcon from 'images/contact/contactFormCloseIcon.svg'
import checked from '../images/contact/checked.svg'
import unChecked from '../images/contact/unChecked.svg'

class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: {
        company: '',
        companySize: '',
        email: '',
        fullname: '',
        projectMessage: '',
        servicesMessage: '',
        telephone: ''
      },
      loading: false,
      response: null
    }

    this.formRef = React.createRef()
  }

  handleBlur (event) {
    event.preventDefault()
    const { maxLength, name, value, required } = event.target

    let errorMessage = ''

    if (value.length === 0 && required) errorMessage = 'Field cannot be empty'
    if (value.length >= maxLength) errorMessage = `Characters may not exceed ${maxLength}`
    if (name === 'email' && value) {
      const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
      )
      errorMessage = !validEmailRegex.test(value) ? 'Incorrect format' : ''
    }
    if (name === 'email' && value.length >= maxLength) {
      errorMessage = `Characters may not exceed ${maxLength}`
    }

    this.setState(prevState => ({
      error: {
        ...prevState.error,
        [name]: errorMessage
      }
    }))
  }

  resetForm () {
    this.formRef.current.reset()
  }

  handleSubmit (event) {
    event.preventDefault()
    event.persist()
    this.setLoading(true)
    const cloudUrl = 'https://us-central1-deimos-cloud.cloudfunctions.net/sendContactUsEmail'

    const formData = serializeForm(event.target)

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'formComplete'
      })
    }

    // eslint-disable-next-line no-undef
    fetch(cloudUrl, {
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'no-cors'
    })
      .then(res =>
        res.json().then(data => {
          if (!res.ok) {
            throw Error(data.err || 'HTTP error')
          }
          return data
        })
      )
      .then(() => {
        this.resetForm()
        this.setResponse('success', 'Transmission Complete!')
      })
      .catch(() => {
        this.setResponse(
          'failed',
          'Transmission Failed due to solar winds. Please try again later.'
        )
      })
  }

  handleAnotherMessage () {
    this.setState({ response: null })
    this.setState({ inputVisibility: false })
    this.setState({ textareaVisibility: false })
  }

  setResponse (status, message) {
    this.setState({
      loading: false,
      response: {
        message,
        status
      }
    })
  }

  setLoading (val) {
    this.setState({ loading: val })
  }

  displayResponse (response) {
    const { status, message } = response
    let className
    if (status === 'failed') {
      className = ContactStyles.errorMessage
    } else if (status === 'success') {
      className = ContactStyles.successMessage
    }

    return <p className={className}>{message}</p>
  }

  contactServices (services) {
    if (services) {
      let newArray = []
      const serviceNames = services.edges[0].node.footersFieldGroup.contactFormServiceName

      return serviceNames.map((service) => {
        newArray.push({ value: service.serviceLabel })
        return newArray
      })
    }
  }

  handleClose () {
    const contactFormClassList =
      typeof document !== 'undefined' &&
      document.getElementById('contactFormPopup').classList
    const modal = document.getElementById('popupModal')

    if (contactFormClassList.contains(ContactStyles.hideForm)) {
      contactFormClassList.remove(ContactStyles.hideForm)
    } else {
      contactFormClassList.add(ContactStyles.hideForm)
    }

    modal.style.display = 'none'

    this.props.onFormClosed()
  }

  checkTextareaVisibilityToggle () {
    const checkOptionalTextarea = document.getElementById('projectMessage')
    if (checkOptionalTextarea.style.display === 'block') {
      checkOptionalTextarea.style.display = 'none'
      this.setState({ textareaVisibility: false })
    } else {
      checkOptionalTextarea.style.display = 'block'
      this.setState({ textareaVisibility: true })
    }
  }

  checkedInputVisibilityToggle () {
    const checkOptionalInput = document.getElementById('contactTel')
    if (checkOptionalInput.style.display === 'block') {
      checkOptionalInput.style.display = 'none'
      this.setState({ inputVisibility: false })
    } else {
      checkOptionalInput.style.display = 'block'
      this.setState({ inputVisibility: true })
    }
  }

  render () {
    const { error, loading, response, inputVisibility, textareaVisibility } = this.state
    const { services } = this.props

    return (
      <div
        className={ContactStyles.contactFormPopup}
        id='contactFormPopup'
      >
        {!response ? (
          <div className={ContactStyles.flexWrapper}>
            <div className={ContactStyles.flexContainer}>
              <h5>LET'S CHAT</h5>
              <h1>Let one of our certified experts get in touch with you</h1>
            </div>
            <form
              className={ContactStyles.formContainer}
              id='contact-form-general'
              onSubmit={event => this.handleSubmit(event)}
              ref={this.formRef}
            >
              <span
                className={ContactStyles.closeIcon}
                onClick={() => this.handleClose()}
              >
                <img src={closeIcon} />
              </span>
              <Input
                errMsg={error.fullname}
                id='contactFullname'
                label='Full name'
                maxLength='32'
                name='fullname'
                onBlur={event => this.handleBlur(event)}
                required
                type='text'
              />
              <Input
                errMsg={error.email}
                id='contactEmail'
                label='Email address'
                maxLength='33'
                name='email'
                onBlur={event => this.handleBlur(event)}
                required
                type='email'
              />
              <label>
                <span
                  className={ContactStyles.labelStyles}
                  id='checkedInput'
                  onClick={() => this.checkedInputVisibilityToggle()}>
                  {inputVisibility ? (
                    <img
                      alt='checked Icon'
                      height={12}
                      src={checked}
                      width={24}
                    />
                  ) : (
                    <img
                      alt='unChecked Icon'
                      height={12}
                      src={unChecked}
                      width={24}
                    />
                  )
                  }
                  <span className={ContactStyles.optionalText}>
                    Add your phone number if you’d prefer a call
                    <span className={ContactStyles.optionalStyle}> (optional)</span>
                  </span>
                </span>
                <Input
                  className={ContactStyles.inputAlt}
                  errMsg={error.telephone}
                  id='contactTel'
                  maxLength='19'
                  name='telephone'
                  type='tel'
                />
              </label>
              <span className={ContactStyles.companySizeInputWrapper}>
                <Input
                  errMsg={error.company}
                  error
                  id='contactCompany'
                  label='Company name'
                  maxLength='15'
                  name='company'
                  onBlur={event => this.handleBlur(event)}
                  required
                  type='text'
                />
              </span>
              <span className={ContactStyles.companySizeContainer}>
                <label><span className={ContactStyles.companySizeHeading}>
                  What is the size of your company?
                  <span className={ContactStyles.optionalStyle}> (optional)</span>
                </span>
                </label>
                <div className={ContactStyles.companySizeRadioWrapper}>
                  <label>
                    <input
                      id='minCompanySize'
                      name='companySize'
                      type='radio'
                      value='lessThan50' />
                    1 - 50 employees
                  </label>
                </div>
                <div className={ContactStyles.companySizeRadioWrapper}>
                  <label>
                    <input
                      id='midCompanySize'
                      name='companySize'
                      type='radio'
                      value='lessThan300' />
                    51 - 299 employees
                  </label>
                </div>
                <div className={ContactStyles.companySizeRadioWrapper}>
                  <label
                  >
                    <input
                      id='maxCompanySize'
                      name='companySize'
                      type='radio'
                      value='moreThan300' />
                    300+ employees
                  </label>
                </div>
              </span>
              {services && (
                <CheckboxList
                  className={ContactStyles.checkboxWrapper}
                  label={formatHTMLEntity(
                    'What services do you need help with? '
                  )}
                  name='services'
                  options={this.contactServices(services)}
                />
              )}
              <div className={ContactStyles.projectInfoWrapper}>
                <label>
                  <span
                    className={ContactStyles.labelStyles}
                    id='checkedTextarea'
                    onClick={() => this.checkTextareaVisibilityToggle()}>
                    {textareaVisibility ? (
                      <img
                        alt='unChecked Icon'
                        height={12}
                        src={checked}
                        width={24}
                      />
                    ) : (
                      <img
                        alt='checked Icon'
                        height={12}
                        src={unChecked}
                        width={24}
                      />
                    )
                    }
                    <span className={composeClasses(ContactStyles.optionalText, ContactStyles.projectInfoHeading)}>
                      What's your project all about?
                      <span className={ContactStyles.optionalStyle}> (optional)</span>
                    </span>
                  </span>
                </label>
                <div
                  className={ContactStyles.textAreaMessage}
                  id='projectMessage'
                >
                  <TextArea
                    errMsg={error.projectMessage}
                    maxLength='200'
                    name='projectMessage'
                    onBlur={event => this.handleBlur(event)}
                  />
                </div>
              </div>
              <div className={ContactStyles.buttonWrapper}>
                <Button
                  className={ContactStyles.submitBtn}
                  disabled={error.company !== '' || error.fullname !== '' || error.email !== ''}
                  name={'send'}
                  type={'submit'}
                >
                  {loading ? <Loading /> : 'Submit'}
                </Button>
                <div className={ContactStyles.contactInfo}>
                  <div className={ContactStyles.infoContainer}>
                    <span name='email'>
                      <a href='mailto:info@deimos.co.za'>
                        info@deimos.co.za
                      </a>
                    </span>
                    <span name='phone'>
                      <a href='tel:+27873541286'>
                        +27 87 3541 286
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <>
            <div className={ContactStyles.successScreen}>
              <div
                className={ContactStyles.closeSuccessIcon}
                onClick={() => this.handleClose()}
              >
                <img src={closeIcon} />
              </div>
              <span className={ContactStyles.successIconStyle}>
                <img
                  alt='successIcon'
                  src={successIcon} />
              </span>
              <div className={ContactStyles.successPageMessage}>
                <h2>Thanks for your enquiry!</h2>
                <p>
                  Keep an eye on your inbox. We’ll be with you within 24
                  hours.
                </p>
              </div>
              <button
                className={composeClasses(
                  'contactUsButton',
                  ContactStyles.contactUsButton
                )}
                onClick={() => this.handleAnotherMessage()}
              >
                Send us another message
              </button>
              <button
                className={composeClasses(
                  'contactUsCloseButton',
                  ContactStyles.contactUsButton
                )}
                onClick={() => this.handleClose()}
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    )
  }
}

ContactForm.propTypes = {
  onFormClosed: PropTypes.func,
  services: PropTypes.object
}

export default ContactForm
