exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-application-modernisation-js": () => import("./../../../src/templates/application-modernisation.js" /* webpackChunkName: "component---src-templates-application-modernisation-js" */),
  "component---src-templates-application-security-audit-js": () => import("./../../../src/templates/application-security-audit.js" /* webpackChunkName: "component---src-templates-application-security-audit-js" */),
  "component---src-templates-architecture-assessment-js": () => import("./../../../src/templates/architecture-assessment.js" /* webpackChunkName: "component---src-templates-architecture-assessment-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-client-detail-js": () => import("./../../../src/templates/client-detail.js" /* webpackChunkName: "component---src-templates-client-detail-js" */),
  "component---src-templates-clients-js": () => import("./../../../src/templates/clients.js" /* webpackChunkName: "component---src-templates-clients-js" */),
  "component---src-templates-cloud-cost-optimization-js": () => import("./../../../src/templates/cloud-cost-optimization.js" /* webpackChunkName: "component---src-templates-cloud-cost-optimization-js" */),
  "component---src-templates-cloud-native-architecture-consulting-engineering-js": () => import("./../../../src/templates/cloud-native-architecture-consulting-engineering.js" /* webpackChunkName: "component---src-templates-cloud-native-architecture-consulting-engineering-js" */),
  "component---src-templates-cloud-native-site-reliability-architecture-consulting-and-engineering-js": () => import("./../../../src/templates/cloud-native-site-reliability-architecture-consulting-and-engineering.js" /* webpackChunkName: "component---src-templates-cloud-native-site-reliability-architecture-consulting-and-engineering-js" */),
  "component---src-templates-cloud-native-site-reliability-engineering-js": () => import("./../../../src/templates/cloud-native-site-reliability-engineering.js" /* webpackChunkName: "component---src-templates-cloud-native-site-reliability-engineering-js" */),
  "component---src-templates-cloud-native-software-engineering-js": () => import("./../../../src/templates/cloud-native-software-engineering.js" /* webpackChunkName: "component---src-templates-cloud-native-software-engineering-js" */),
  "component---src-templates-cloud-native-support-engineering-js": () => import("./../../../src/templates/cloud-native-support-engineering.js" /* webpackChunkName: "component---src-templates-cloud-native-support-engineering-js" */),
  "component---src-templates-cloud-security-architecture-and-consulting-js": () => import("./../../../src/templates/cloud-security-architecture-and-consulting.js" /* webpackChunkName: "component---src-templates-cloud-security-architecture-and-consulting-js" */),
  "component---src-templates-cloud-security-assessment-js": () => import("./../../../src/templates/cloud-security-assessment.js" /* webpackChunkName: "component---src-templates-cloud-security-assessment-js" */),
  "component---src-templates-cloud-security-audit-js": () => import("./../../../src/templates/cloud-security-audit.js" /* webpackChunkName: "component---src-templates-cloud-security-audit-js" */),
  "component---src-templates-cloud-security-js": () => import("./../../../src/templates/cloud-security.js" /* webpackChunkName: "component---src-templates-cloud-security-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-continuous-delivery-js": () => import("./../../../src/templates/continuous-delivery.js" /* webpackChunkName: "component---src-templates-continuous-delivery-js" */),
  "component---src-templates-culture-and-values-js": () => import("./../../../src/templates/culture-and-values.js" /* webpackChunkName: "component---src-templates-culture-and-values-js" */),
  "component---src-templates-fitting-into-your-business-js": () => import("./../../../src/templates/fitting-into-your-business.js" /* webpackChunkName: "component---src-templates-fitting-into-your-business-js" */),
  "component---src-templates-google-cloud-migration-js": () => import("./../../../src/templates/google-cloud-migration.js" /* webpackChunkName: "component---src-templates-google-cloud-migration-js" */),
  "component---src-templates-google-cloud-platform-js": () => import("./../../../src/templates/google-cloud-platform.js" /* webpackChunkName: "component---src-templates-google-cloud-platform-js" */),
  "component---src-templates-google-kubernetes-engine-js": () => import("./../../../src/templates/google-kubernetes-engine.js" /* webpackChunkName: "component---src-templates-google-kubernetes-engine-js" */),
  "component---src-templates-google-workspace-js": () => import("./../../../src/templates/google-workspace.js" /* webpackChunkName: "component---src-templates-google-workspace-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-infrastructure-assessment-js": () => import("./../../../src/templates/infrastructure-assessment.js" /* webpackChunkName: "component---src-templates-infrastructure-assessment-js" */),
  "component---src-templates-infrastructure-modernisation-js": () => import("./../../../src/templates/infrastructure-modernisation.js" /* webpackChunkName: "component---src-templates-infrastructure-modernisation-js" */),
  "component---src-templates-internship-js": () => import("./../../../src/templates/internship.js" /* webpackChunkName: "component---src-templates-internship-js" */),
  "component---src-templates-kubernetes-assessment-js": () => import("./../../../src/templates/kubernetes-assessment.js" /* webpackChunkName: "component---src-templates-kubernetes-assessment-js" */),
  "component---src-templates-kubernetes-js": () => import("./../../../src/templates/kubernetes.js" /* webpackChunkName: "component---src-templates-kubernetes-js" */),
  "component---src-templates-managed-infrastructure-js": () => import("./../../../src/templates/managed-infrastructure.js" /* webpackChunkName: "component---src-templates-managed-infrastructure-js" */),
  "component---src-templates-managed-kubernetes-js": () => import("./../../../src/templates/managed-kubernetes.js" /* webpackChunkName: "component---src-templates-managed-kubernetes-js" */),
  "component---src-templates-member-detail-js": () => import("./../../../src/templates/member-detail.js" /* webpackChunkName: "component---src-templates-member-detail-js" */),
  "component---src-templates-member-js": () => import("./../../../src/templates/member.js" /* webpackChunkName: "component---src-templates-member-js" */),
  "component---src-templates-migrating-js": () => import("./../../../src/templates/migrating.js" /* webpackChunkName: "component---src-templates-migrating-js" */),
  "component---src-templates-observability-js": () => import("./../../../src/templates/observability.js" /* webpackChunkName: "component---src-templates-observability-js" */),
  "component---src-templates-on-prem-infrastructure-audit-js": () => import("./../../../src/templates/on-prem-infrastructure-audit.js" /* webpackChunkName: "component---src-templates-on-prem-infrastructure-audit-js" */),
  "component---src-templates-our-initiatives-js": () => import("./../../../src/templates/our-initiatives.js" /* webpackChunkName: "component---src-templates-our-initiatives-js" */),
  "component---src-templates-partnerships-js": () => import("./../../../src/templates/partnerships.js" /* webpackChunkName: "component---src-templates-partnerships-js" */),
  "component---src-templates-platform-modernisation-js": () => import("./../../../src/templates/platform-modernisation.js" /* webpackChunkName: "component---src-templates-platform-modernisation-js" */),
  "component---src-templates-post-category-detail-js": () => import("./../../../src/templates/post-category-detail.js" /* webpackChunkName: "component---src-templates-post-category-detail-js" */),
  "component---src-templates-post-detail-js": () => import("./../../../src/templates/post-detail.js" /* webpackChunkName: "component---src-templates-post-detail-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-product-ideation-js": () => import("./../../../src/templates/product-ideation.js" /* webpackChunkName: "component---src-templates-product-ideation-js" */),
  "component---src-templates-professional-services-js": () => import("./../../../src/templates/professional-services.js" /* webpackChunkName: "component---src-templates-professional-services-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-security-trust-js": () => import("./../../../src/templates/security-trust.js" /* webpackChunkName: "component---src-templates-security-trust-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-tag-details-js": () => import("./../../../src/templates/tag-details.js" /* webpackChunkName: "component---src-templates-tag-details-js" */)
}

