import React, { useState } from 'react'
import parse from 'html-react-parser'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import ftImageWhite from 'images/ftImageWhite.png'
import * as styles from 'styles/newsletterSignup.module.css'

const NewsletterSignUp = () => {
  const [email, setEmail] = useState('')
  const [validationMessage, setValidationMessage] = useState('')

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email, { TAG: 'Newsletter' })
      .then(data => {
        if (data.result === 'error') {
          setValidationMessage(parse(data.msg))
        } else {
          setValidationMessage(data.msg)
        }
      })
      .catch(() => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  return (
    <div className={styles.subscribeSection}>
      <h3 className={styles.subscribeSectionHeader}>
        Sign up for our newsletter!
      </h3>
      <form onSubmit={handleSubmit}>
        <div className={styles.subscribeSectionEmailInputWrapper}>
          <label
            aria-label='newsletter signup email address'
            htmlFor='email_address'
          >
            Email Address
          </label>
          <input
            className={styles.subscribeSectionEmailInput}
            id='email_address'
            name='email'
            onChange={handleEmailChange}
          />
          <button
            aria-label='submitButton'
            className={styles.subscribeSectionEmailButton}
            type='submit'
          >
            Submit
          </button>
        </div>
        {validationMessage !== '' && (
          <p className={styles.validationMessage}>{validationMessage}</p>
        )}
        <a
          href='https://www.ft.com/content/a1bc5d2e-046e-499b-b27e-4d057f9d8477'
          rel='noopener noreferrer'
          target={'_blank'}>
          <img
            alt='client logo'
            src={ftImageWhite}
            width='200' />
        </a>
      </form>
    </div>
  )
}

export default NewsletterSignUp
