import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContactForm from '../components/contactForm'

import {
  modalContainer,
  contactFormContainer
} from 'styles/contact.module.css'

const ContactFormComponent = ({ services }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onFormClosed = () => {
    setIsVisible(false)
  }

  return (
    <div className={contactFormContainer}>
      <div
        className={modalContainer}
        id='popupModal'>
        <ContactForm
          isPopup
          onFormClosed={onFormClosed}
          services={services}
          showForm={isVisible}
        />
      </div>
    </div>
  )
}

ContactFormComponent.propTypes = {
  services: PropTypes.object
}

export default ContactFormComponent
