// extracted by mini-css-extract-plugin
export var deimosMainLogoHeight = "header-module--deimosMainLogoHeight--btdBP";
export var deimosSecondaryLogoHeight = "header-module--deimosSecondaryLogoHeight--0xCNC";
export var shrink = "header-module--shrink--1MCIW";
export var headerContainer = "header-module--headerContainer--hV2Dm";
export var primaryLogo = "header-module--primaryLogo--SpdON";
export var link = "header-module--link--qTn+Q";
export var subLink = "header-module--subLink--dty4+";
export var subNav = "header-module--subNav--cto45";
export var singleItem = "header-module--singleItem--cc3eQ";
export var desktopLinks = "header-module--desktopLinks--ANFlR";
export var activeLink = "header-module--activeLink--zzTwU";
export var activeNavLink = "header-module--activeNavLink--rM8Lz";
export var innerSubNavLinkActive = "header-module--innerSubNavLinkActive--wXn47";
export var innerSubNavLink = "header-module--innerSubNavLink--OnCEh";
export var header = "header-module--header--UabsJ";
export var subNavDiv = "header-module--subNavDiv--FIHf9";
export var subNavDivli = "header-module--subNavDivli--3CuWU";
export var sidebar = "header-module--sidebar--k4RQL";
export var backTextButton = "header-module--backTextButton--wsCYp";
export var subNavLink = "header-module--subNavLink--WPBlN";
export var subNavFontWeight = "header-module--subNavFontWeight--MzGyu";
export var innerSubNavLinkInner = "header-module--innerSubNavLinkInner--T66lm";
export var svgLogo = "header-module--svgLogo--jQMBM";
export var deimosMainLogo = "header-module--deimosMainLogo--wyNRu";
export var mySideNav = "header-module--mySideNav--cV7C3";
export var closeSideNav = "header-module--closeSideNav--svIFm";
export var closeBtn = "header-module--closeBtn--zEa0l";
export var closeBtnContainer = "header-module--closeBtnContainer--+MBOA";
export var sideLink = "header-module--sideLink--YCc9V";
export var hamburgerBtn = "header-module--hamburgerBtn--9s9Du";
export var mobileLinksContainer = "header-module--mobileLinksContainer--VeYIx";
export var hide = "header-module--hide--pseKx";
export var menu = "header-module--menu--CYSe2";
export var hamburgerBar = "header-module--hamburgerBar--JDb0W";
export var hamburgerMenu = "header-module--hamburgerMenu--BzcZD";
export var sectionWrapper = "header-module--sectionWrapper--8EC1y";
export var sectionTitle = "header-module--sectionTitle--1orCG";
export var section = "header-module--section--JI08s";
export var submenuLink = "header-module--submenuLink--VStcz";
export var deimosIcon = "header-module--deimosIcon--QUFxJ";
export var menuContainer = "header-module--menuContainer--vw97+";
export var activeSubNavLink = "header-module--activeSubNavLink--d-6mk";