/* eslint-disable */
import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'gatsby'
import * as HeaderStyles from 'styles/header.module.css'
import CloseSVG from 'images/close'
import deimosIcon from 'images/deimosIcon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Accordion from '../components/accordion'
import {
  sectionWrapper,
  section,
  submenuLink
} from 'styles/header.module.css'

const Menu = ({ menuItems }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  const [arrow] = useState(faAngleRight)
  const toggleSubNav = e => {
    const subNav =
      typeof document !== 'undefined' && document.getElementById('subNav')

    if (subNav.style.display === 'none') {
      e.preventDefault()
      subNav.style.display = 'block'
      subNav.style.opacity = 1
      subNav.parentElement.style.background = 'rgba(76, 135, 175, 0.15)'
      subNav.parentElement.style.paddingTop = '0px'
      document.getElementById('innerMenuSidebar').style.width = '100%'
    } else {
      e.preventDefault()
      document.getElementById('innerMenuSidebar').style.width = '0'
      document.getElementById('main').style.marginLeft = '0'
      subNav.style.display = 'none'
      subNav.style.opacity = 0
      subNav.parentElement.style.background = 'none'
    }
  }

  const closeNav = () => {
    document.getElementById('innerMenuSidebar').style.width = '0'
    document.getElementById('main').style.marginLeft = '0'
  }

  const topLevelMenuItem =
    menuItems && menuItems.edges[0].node.menuFieldGroup.topLevelMenuItem

  return (
    <div>
      <div>
        <button
          aria-expanded="false"
          className={HeaderStyles.hamburgerBtn}
          onClick={toggleOpen}
          role='button'
          type='button'
        >
          <span
            aria-label='hamburgerBar'
            className={HeaderStyles.hamburgerBar}
            role='navigation'
          />
          <span
            aria-label='hamburgerBar'
            className={HeaderStyles.hamburgerBar}
            role='navigation'
          />
          <span
            aria-label='hamburgerBar'
            className={HeaderStyles.hamburgerBar}
            role='navigation'
          />
          <span
            aria-label='hamburgerBar'
            className={HeaderStyles.hamburgerMenu}
            role='navigation'
          >
            Menu
          </span>
        </button>
      </div>

      <div
        className={`${HeaderStyles.mySideNav} ${!isOpen &&
          HeaderStyles.closeSideNav}`}
        id='mySidenav'
      >
        <div className={HeaderStyles.closeBtnContainer}>
          <img
            alt='deimosIcon'
            className={HeaderStyles.deimosIcon}
            height={50}
            src={deimosIcon}
            width={80}
          />
          <button
            aria-label='close button'
            className={HeaderStyles.closeBtn}
            onClick={toggleOpen}
            type='button'
          >
            <CloseSVG />
          </button>
        </div>
        <div
          className={HeaderStyles.mobileLinksContainer}
          id='main'>
          {topLevelMenuItem.map((item, index) => {
            const { label, slug } = item
            const hasSubmenu = item.hasSubmenu
            const secondLevelMenuItem = hasSubmenu && item.secondLevelMenuItem
            return (
              <div
                className={HeaderStyles.menuContainer}
                key={index.toString()}
              >
                <Link
                  activeClassName={HeaderStyles.activeNavLink}
                  className={HeaderStyles.sideLink}
                  onClick={hasSubmenu ? e => toggleSubNav(e) : toggleOpen}
                  to={`/${slug === 'index' ? '' : slug}`}
                >
                  {label} {hasSubmenu && <FontAwesomeIcon icon={arrow} />}
                </Link>
                {hasSubmenu && (
                  <div
                    className={HeaderStyles.sidebar}
                    id='innerMenuSidebar'>
                    <div
                      className={HeaderStyles.backTextButton}
                      onClick={closeNav}><FontAwesomeIcon icon={faArrowLeft} />  Professional Services</div>
                    <ul
                      className={HeaderStyles.subNav}
                      id='subNav'>
                      <div
                        className={sectionWrapper}
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <div>
                          <div className={section}>
                            {secondLevelMenuItem && secondLevelMenuItem.map((subItem, index) => {
                              return (
                                <div key={index.toString()}>
                                  {
                                    subItem && <Accordion
                                      content={
                                        subItem.innerSubmenu && subItem.thirdLevelMenuItem.map((innerMenuItem, index) => {
                                          return (
                                            <li key={index.toString()}>
                                              <a
                                                className={index !== 0 ? HeaderStyles.innerSubNavLinkInner : HeaderStyles.innerSubNavLink}
                                                href={`/${innerMenuItem.innerSubmenuUrl}`}

                                              >
                                                {index === 0 ? `${innerMenuItem.innerSubmenuItem} Homepage` : innerMenuItem.innerSubmenuItem}
                                              </a>
                                            </li>
                                          )
                                        })
                                      }
                                      contentTitle={
                                        subItem.innerSubmenu === null
                                          ? <a
                                            className={submenuLink}
                                            href='/professional-services/cloud-native-support-engineering'>
                                            {subItem.submenuItemName}
                                          </a>
                                          : subItem.submenuItemName}
                                      imageId={subItem.submenuItemName}
                                      menuStyle
                                      numbers=''
                                    />
                                  }
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  menuItems: PropTypes.object.isRequired
}

export default Menu
